export default {
    data() {
        return {
            isDirty: false
        };
    },
    methods: {
        setDirty(val) {
            this.isDirty = val;
        },
        getDirty() {
            return this.isDirty;
        },
        onLeave(next) {
            next();
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.isDirty) {
            this.$dialog.confirm(this.$t('confirm.leaveWithoutSave'))
                .then(() => {
                    this.onLeave(next);
                })
                .catch(() => { });
        } else {
            this.onLeave(next);
        }
    }
};