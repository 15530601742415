<template>
    <div v-if="initialized">
        <div class="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div class="row">
                <div class="col-6">
                    <h4 class="tx-gray-800 mg-b-5">{{ $t('title.customers') }} </h4>
                </div>
                <div class="col-6">
                    <div class="br-pageheader float-right">
                        <nav class="breadcrumb pd-0 mg-0 tx-12 float-right">
                            <router-link class="breadcrumb-item" :to="{name: 'customerList', params: { id: $route.params.id }}">{{ $t('title.customers') }}</router-link>
                            <span class="breadcrumb-item active">{{record.name}}</span>
                        </nav>
                    </div><!-- br-pageheader -->
                </div>
            </div>
        </div>

        <form @submit.prevent="saveChanges">
            <div class="br-pagebody">
                <div class="br-section-wrapper">

                    <h6 class="tx-gray-800 tx-uppercase tx-bold tx-14 mg-b-10">{{ $t('actions.edit') }}</h6>
                    <div class="row mg-t-15">
                        <div class="col-4">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('name')">{{$t('name')}}:</label><span class="tx-danger">*&nbsp;</span>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.name" name="name" type="text" />
                        </div>
                    </div>


                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('identifier')">{{$t('identifier')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.identifier" name="identifier" />
                        </div>
                    </div>



                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('domain')">{{$t('domain')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.domain" name="domain" />
                        </div>
                    </div>

                    <div class="row mg-t-15">
                        <div class="col-4">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('desktopapiaddress')">{{$t('desktopapiaddress')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.desktopApiAddress" name="desktopapiaddress" />
                        </div>
                    </div>
                    <div class="row mg-t-15">
                        <div class="col-4">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('editoraddress')">{{$t('editoraddress')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.editorAddress" name="editoraddress" />
                        </div>
                    </div>
                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('appversion')">{{$t('appversion')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <selectenumtype url="/api/enums"
                                            class="wd-300"
                                            :value="record.appVersion"
                                            v-model="record.appVersion"
                                            enumtype="appversion">
                            </selectenumtype>
                        </div>
                    </div>

                    <div class="row mg-t-30">
                        <div class="col-4">
                        </div>
                        <div class="col-sm-8 mg-l-auto">
                            <div class="form-layout-footer">
                                <button class="btn btn-success mg-r-15" id="Submit" name="Submit" type="submit">{{ $t('actions.save') }}</button>

                            </div><!-- form-layout-footer -->
                        </div><!-- col-8 -->
                    </div>
                </div>

            </div>
        </form>
    </div>
</template>

<script>

    import toastr from 'toastr';
    import checkBeforeLeaveMixin from '@/mixin/index.js';

    export default {
        name: 'CustomerEdit',
        mixins: [checkBeforeLeaveMixin],
        data() {
            return {
                initialized: false,
                record: {
                    id: -1,
                    name: '',
                    identifier: '',
                    domain: '',
                    desktopApiAddress: '',
                    editorAddress: '',
                    appVersion: 0,
                },
            };
        },
        mounted() {
            this.fetch();
        },
        watch: {
            '$route'() {
                this.fetch();
            },
            record: {
                handler() {
                    this.setDirty(true);
                },
                deep: true
            },


        },
        computed: {

        },
        methods: {
            async fetch() {
                if (this.$route.params.id > 0) {
                    const client = await this.$api.getClient();
                    const resp = await client.GetCustomer(this.$route.params.id);
                    this.record = resp.data;
                    this.initialized = true;
                    this.$nextTick(function () { this.setDirty(false); });
                } else {
                    this.initialized = true;
                    this.setDirty(false);
                }
            },
            async saveChanges() {
                const client = await this.$api.getClient();
                try {

                    await client.SaveCustomer(null, this.record);
                    this.setDirty(false);
                    toastr.success(this.$t('success.saveChanges'), '');
                    this.$router.push({ name: 'customers' });                    
                } catch (err) {
                    toastr.error(this.$t('error.saveChanges'), err);
                }
            },


        }
    };

</script>
